/* eslint-disable camelcase */
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import { removeUndefinedFields } from '../../shared/helpers/remove-undefined-fields';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import { IProducingRegion } from '../../shared/interfaces/IProducingRegion';
import { IRequest } from '../../shared/interfaces/IRequest';

interface IRegion {
  producingRegions: IProducingRegion[];
  type: string;
  remaining_limit: number;
  total_limit: number;
}

interface IRequestPdfDownloadUrl {
  downloadUrl: string;
}

interface IRequestJsonDownloadUrl {
  data: unknown;
}

type RequestPcfProps = {
  product_id?: number;
  region_type?: string;
  file_type?: string[];
  request_type: string;
  region?: string;
  request_description?: string;
};

export type PcfRequestFilter = {
  request_type?: string;
  search?: string; // product_name and/or requested_by
};

const amplifyApiService = new AmplifyApiService();

export const useGetPcfRequests = (
  filter: PcfRequestFilter = {},
): UseQueryResult<IRequest[], Error> => {
  // Clear undefined values from filter
  const _filter = removeUndefinedFields(filter);

  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'request',
    init: {
      queryStringParameters: { ..._filter },
    },
  };

  const query = useQuery<IRequest[], Error>({
    queryKey: ['requests', _filter],
    queryFn: async (): Promise<IRequest[]> => {
      const response = await amplifyApiService.get<{ requests: IRequest[] }>({
        config: amplifyApiConfig,
      });

      return response.requests;
    },
  });

  return query;
};

export const useGetRequestRegion = (id: string): UseQueryResult<IRegion> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: `regions/${id}`,
    init: {},
  };

  const query = useQuery<IRegion, Error>({
    queryKey: ['regions', id],
    queryFn: async (): Promise<IRegion> => {
      const response = await amplifyApiService.get<IRegion>({
        config: amplifyApiConfig,
      });

      return response;
    },
  });

  return query;
};

export const useCreatePcfRequest = (
  options: UseMutationOptions<void, Error, RequestPcfProps>,
): UseMutationResult<void, Error, RequestPcfProps> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'request',
    init: {},
  };

  const mutation = useMutation<void, Error, RequestPcfProps>({
    mutationFn: async (requestPcf: RequestPcfProps) => {
      await amplifyApiService.post<RequestPcfProps, { id: number }>({
        config: amplifyApiConfig,
        body: requestPcf,
      });
    },
    ...options,
  });

  return mutation;
};

export const useGetRequestPdfDownloadUrl = (
  id?: number,
): UseQueryResult<IRequestPdfDownloadUrl> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: `request/${id}?downloadPdfUrl=1`,
    init: {},
  };

  const query = useQuery<IRequestPdfDownloadUrl, Error>({
    queryKey: ['request-download-pdf-url', id],
    queryFn: async (): Promise<IRequestPdfDownloadUrl> => {
      const response = await amplifyApiService.get<IRequestPdfDownloadUrl>({
        config: amplifyApiConfig,
      });

      return response;
    },
    enabled: false,
  });

  return query;
};

export const useGetRequestJsonDownloadUrl = (
  id?: number,
): UseQueryResult<IRequestJsonDownloadUrl> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: `request/${id}?downloadJson=1`,
    init: {},
  };

  const query = useQuery<IRequestJsonDownloadUrl, Error>({
    queryKey: ['request-download-json', id],
    queryFn: async (): Promise<IRequestJsonDownloadUrl> => {
      const response = await amplifyApiService.get<IRequestJsonDownloadUrl>({
        config: amplifyApiConfig,
      });

      return response;
    },
    enabled: false,
  });

  return query;
};
