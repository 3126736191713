import { IconOptions } from 'primereact/utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ProductsFilledIcon = (options: IconOptions) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='currentcolor'
      xmlns='http://www.w3.org/2000/svg'
      {...options.iconProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.27847 3.80531C4.26369 4.79703 2.62913 5.6223 2.64605 5.63922C2.6947 5.68787 9.9368 9.50999 9.98361 9.51171C10.0248 9.51327 17.2999 5.53658 17.3542 5.48289C17.3857 5.45175 10.0903 1.9966 10.0003 2C9.96802 2.00121 8.29321 2.81363 6.27847 3.80531ZM14.6984 8.28426C12.6296 9.41922 10.9273 10.3575 10.9154 10.3694C10.9036 10.3813 11.3163 10.9195 11.8327 11.5653L12.7716 12.7396L15.9477 10.9218C17.6946 9.92194 19.321 8.98992 19.5619 8.85055L20 8.59724L19.8355 8.33439C19.1463 7.23353 18.4955 6.21876 18.4792 6.21962C18.4684 6.2202 16.7671 7.14929 14.6984 8.28426ZM0.742942 7.48333L0 8.59677L0.125699 8.67366C0.194859 8.71594 1.76358 9.61553 3.61175 10.6727C5.45992 11.7299 7.02649 12.628 7.09304 12.6686L7.21405 12.7423L8.1243 11.6024C8.62491 10.9754 9.03643 10.4389 9.03874 10.4102C9.04225 10.3668 5.7645 8.61001 2.11903 6.70135L1.48592 6.36989L0.742942 7.48333ZM1.87298 12.993V14.9228L5.62665 16.9042C7.69117 17.994 9.38801 18.878 9.39743 18.8685C9.40685 18.8591 9.41009 17.2842 9.40458 15.3687L9.39462 11.886L8.59361 12.8901C8.15306 13.4423 7.73286 13.9341 7.65984 13.983C7.49538 14.0931 7.27492 14.0984 7.07623 13.997C6.99555 13.9558 5.80682 13.2789 4.4346 12.4927C3.06238 11.7065 1.92464 11.0632 1.90631 11.0632C1.88799 11.0632 1.87298 11.9316 1.87298 12.993ZM15.4126 12.5707C12.5981 14.185 12.665 14.1534 12.3663 14.0116C12.2803 13.9708 11.9763 13.6221 11.4227 12.9293L10.6059 11.9072L10.5957 13.6538C10.5901 14.6144 10.5901 16.1812 10.5957 17.1354L10.6059 18.8706L14.3472 16.8163L18.0885 14.762V12.9126C18.0885 11.8954 18.0753 11.0646 18.0591 11.0664C18.043 11.0681 16.8521 11.745 15.4126 12.5707Z'
        fill={options.fillColor}
      />
    </svg>
  );
};

export default ProductsFilledIcon;
