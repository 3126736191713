import { useContext } from 'react';

import NotificationContext from '../../contexts/notification/NotificationContext';
import { INotificationContext } from '../../shared/interfaces/INotificationContext';

const useNotificationContext = (): INotificationContext => {
  const state: INotificationContext = useContext(NotificationContext);

  if (state === undefined) {
    throw new Error('useNotificationContext must be used within a NotificationProvider');
  }

  return { ...state };
};

export default useNotificationContext;
