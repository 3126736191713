import { PrimeReactContext } from 'primereact/api';
import { useContext, useEffect } from 'react';

import Header from './components/header/Header';

type AppLayoutProps = {
  children: React.ReactNode;
};

const AppLayout = ({ children }: AppLayoutProps) => {
  const { setRipple } = useContext(PrimeReactContext);

  useEffect(() => {
    setRipple(true);
  }, []);

  return (
    <div className='min-h-screen'>
      <Header />
      {children}
    </div>
  );
};

export default AppLayout;
