import { PrimeReactProvider } from 'primereact/api';
import { BrowserRouter } from 'react-router-dom';

import NotificationProvider from './contexts/notification/NotificationProvider';
// import WebsocketProvider from './contexts/websocket/WebsocketProvider';
import { AppRouter } from './routes';

// Fix for iPhone viewport scaling issue
if (navigator.userAgent.indexOf('iPhone') > -1) {
  document
    ?.querySelector('[name=viewport]')
    ?.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
}

function App(): React.ReactElement {
  return (
    <NotificationProvider>
      <PrimeReactProvider>
        {/* <WebsocketProvider> */}
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
        {/* </WebsocketProvider> */}
      </PrimeReactProvider>
    </NotificationProvider>
  );
}

export default App;
