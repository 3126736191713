type ErrorMessageProps = {
  message: string;
};

export default function ErrorMessage({ message }: ErrorMessageProps) {
  return (
    <span className='text-error flex text-sm' role='alert'>
      {message}
    </span>
  );
}
