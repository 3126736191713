import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import useKampylSDKUpdatePageView from '../../../../../hooks/useKampylSDKUpdatePageView';
import styles from './CancelButton.module.css';

type CancelButtonProps = {
  setVisible: (visible: boolean) => void;
  isCreatingAdditionalProductRequest: boolean;
};

const CancelButton = ({
  setVisible,
  isCreatingAdditionalProductRequest,
}: CancelButtonProps): JSX.Element => {
  const { t } = useTranslation();

  // This is a script to be able to show the NPS Dialog
  useKampylSDKUpdatePageView();

  return (
    <div className={styles['cancel-button']}>
      <Button
        className='trigger-nps-dialog'
        label={t('cancelButtonText')}
        text
        onClick={() => {
          setVisible(false);
        }}
        disabled={isCreatingAdditionalProductRequest}
        id='additional-product-request-cancel-btn'
      />
    </div>
  );
};

export default CancelButton;
