import { createContext, useContext } from 'react';

export enum Locale {
  en = 'en',
  de = 'de',
}

type LanguageContextType = {
  language: Locale;
  // changeLanguage: (language: string) => void; // commenting for future use
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

export default LanguageContext;
