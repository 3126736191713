import { useEffect } from 'react';

export default function useKampylSDKUpdatePageView(): void {
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  useEffect(() => {
    // @ts-ignore
    if (window?.KAMPYLE_ONSITE_SDK?.updatePageView) {
      // @ts-ignore
      window.KAMPYLE_ONSITE_SDK.updatePageView();
    }
  }, []);
  /* eslint-enable @typescript-eslint/ban-ts-comment */
}
