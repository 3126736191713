import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { MouseEventHandler } from 'react';

type MobileMenuProps = {
  isOpen?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const MobileMenu = ({ isOpen = false, onClick }: MobileMenuProps) => {
  return (
    <Button
      className='sm:hidden text-white p-0 w-auto mr-3'
      icon={classNames('pi', {
        'pi-times': isOpen,
        'pi-bars': !isOpen,
      })}
      pt={{
        icon: { className: 'text-2xl' },
      }}
      onClick={onClick}
      aria-label='Mobile Menu'
      text
    />
  );
};

export default MobileMenu;
