export enum DeclaredUnit {
  piece = 'piece',
  liter = 'liter',
  kilogram = 'kilogram',
  cubicMeter = 'cubic meter',
  kilowattHour = 'kilowatt hour',
  megajoule = 'megajoule',
  tonKilometer = 'ton kilometer',
  squareMeter = 'square meter',
}

export const DeclaredUnitToSymbolsMap = {
  [DeclaredUnit.piece]: '(pcs)',
  [DeclaredUnit.liter]: '(L)',
  [DeclaredUnit.kilogram]: '(kg)',
  [DeclaredUnit.cubicMeter]: '(m³)',
  [DeclaredUnit.kilowattHour]: '(kW h)',
  [DeclaredUnit.megajoule]: '(MJ)',
  [DeclaredUnit.tonKilometer]: '(tkm)',
  [DeclaredUnit.squareMeter]: '(m²)',
};

interface ICrossSectoralStandard {
  crossSectoralStandard: string;
}

interface IProductOrSectorSpecificRule {
  ruleName: string;
}

interface IProductOrSectorSpecificRules {
  extWBCSD_operator: string;
  productOrSectorSpecificRules: IProductOrSectorSpecificRule[];
  extWBCSD_otherOperatorName: string;
}

interface ISecondaryEmissionFactorSource {
  secondaryEmissionFactorSource: string;
}

interface IPcf {
  declaredUnit: DeclaredUnit;
  unitaryProductAmount: number;
  productMassPerDeclaredUnit: number;
  exemptedEmissionsPercent: number;
  geographyRegionOrSubregion: string;
  referencePeriodStart: string; // ISO date string
  referencePeriodEnd: string; // ISO date string
  crossSectoralStandardsUsed: ICrossSectoralStandard[];
  productOrSectorSpecificRules: IProductOrSectorSpecificRules[];
  extWBCSD_characterizationFactors: string;
  extTFS_allocationWasteIncineration: string;
  secondaryEmissionFactorSources: ISecondaryEmissionFactorSource[];
  extWBCSD_packagingEmissionsIncluded: boolean;
  pcfExcludingBiogenic: number;
  pcfIncludingBiogenic: number;
  carbonContentBiogenic: number;
  carbonContentTotal: number;
}

export interface IProductDetails {
  id: string;
  specVersion: string;
  partialFullPcf: string;
  version: number;
  created: string; // ISO date string
  extWBCSD_pfStatus: string;
  companyName: string;
  companyIds: string[];
  productDescription: string;
  productIds: string[];
  extWBCSD_productCodeCpc: string;
  productName: string;
  pcf: IPcf;
}
