export enum EDCConfigurationStatus {
  PENDING = 'pending',
  FIRST_STEP_COMPLETED = 'first_step_completed',
  COMPLETED = 'completed',
}

export type License = 'basic' | 'pro' | 'automotive';

export interface ICompany {
  id: number;
  company_id: string;
  company_name: string;
  allowed_json_pcf: number;
  edc_configuration_status: EDCConfigurationStatus;
  license: License;
}
