import { Tag, TagProps } from 'primereact/tag';
import { classNames } from 'primereact/utils';

import styles from './ProductIdTag.module.css';

interface ProductIdTagProps extends TagProps {
  productId: string;
}

const ProductIdTag = (props: ProductIdTagProps): JSX.Element => {
  const { productId, className, ...rest } = props;

  return (
    <div>
      <Tag
        value={productId}
        {...rest}
        pt={{
          root: {
            className: classNames(styles['product-id-tag'], className),
          },
        }}
      />
    </div>
  );
};

export default ProductIdTag;
