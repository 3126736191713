import { ToastSeverity } from '../../../shared/enums/toast-severity';
import { IToast } from '../../../shared/interfaces/IToast';

const DetailsContent = ({ heading, subHeading }: { heading: string; subHeading: string }) => {
  return (
    <>
      <p className='m-0 mt-2'>{heading}</p>
      <p className='m-0'>{subHeading}</p>
    </>
  );
};

export const initialProductsListRequest = (
  severity: ToastSeverity,
  summary: string,
  requestHeading: string,
  requestSubHeading: string,
): IToast => {
  return {
    severity: severity,
    summary: summary,
    detail: <DetailsContent heading={requestHeading} subHeading={requestSubHeading} />,
    life: 5000,
  };
};
