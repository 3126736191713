import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import reactUseWebSocket from 'react-use-websocket';

const useWebSocket = () => {
  const [websocketUrl, setWebsocketUrl] = useState<string | null>(null);

  useEffect(() => {
    const prepareWebSocketUrl = async () => {
      const url =
        process.env.REACT_APP_WEBSOCKET_URL +
        '?idToken=' +
        (await Auth.currentSession()).getIdToken().getJwtToken();

      setWebsocketUrl(url);
    };

    prepareWebSocketUrl();
  }, []);

  const { lastMessage: message, readyState } = reactUseWebSocket(websocketUrl);

  return { message, connectionStatus: readyState };
};

export default useWebSocket;
