import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import { RequestType } from '../../shared/enums/request-type';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import { IRequest } from '../../shared/interfaces/IRequest';

type RequestAdditionalProductProps = {
  product_name: string;
  curt_product_id: string;
  request_description?: string;
  request_type?: string;
};

const amplifyApiService = new AmplifyApiService();

export const useCreateAdditionalProductRequest = (
  options: UseMutationOptions<void, Error, RequestAdditionalProductProps>,
): UseMutationResult<void, Error, RequestAdditionalProductProps> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'request',
    init: {},
  };

  const mutation = useMutation<void, Error, RequestAdditionalProductProps>({
    mutationFn: async (requestAdditionalProducts: RequestAdditionalProductProps) => {
      /* eslint-disable-next-line camelcase */
      requestAdditionalProducts.request_type = RequestType.AdditionalProduct;

      await amplifyApiService.post<RequestAdditionalProductProps, IRequest>({
        config: amplifyApiConfig,
        body: requestAdditionalProducts,
      });
    },
    ...options,
  });

  return mutation;
};
