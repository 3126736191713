import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

import ProductSmallInfo from '../../../../../components/product-small-info/ProductSmallInfo';
import { IProduct } from '../../../../../shared/interfaces/IProduct';

type RequestSentContentProps = {
  product: IProduct;
  onDone: () => void;
};

const RequestSentContent = ({ product, onDone }: RequestSentContentProps) => {
  const { t } = useTranslation();

  const renderFooter = () => {
    return <Button label={t('requestSentDialog.doneButtonText')} onClick={onDone} />;
  };

  return (
    <Dialog header={t('requestSentDialog.heading')} visible onHide={onDone} footer={renderFooter}>
      <ProductSmallInfo name={product.product_name} productId={product.product_cid} />
      <div className='grid mt-4'>
        <div className='col-12'>
          <p>{t('requestSentDialog.message')}</p>
          <p>{t('requestSentDialog.descriptionOne')}</p>
        </div>
      </div>
    </Dialog>
  );
};

export default RequestSentContent;
