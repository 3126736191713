import { Navigate } from 'react-router';

import { useAuth } from '../hooks/auth/useAuth';
import { AppRoutes } from '../shared/enums/app-routes';

type ProtectedGuardProps = {
  children: React.ReactNode;
};

const ProtectedGuard = ({ children }: ProtectedGuardProps) => {
  const { isLoading, isAuthenticated } = useAuth();

  if (!isLoading && !isAuthenticated) {
    return <Navigate to={AppRoutes.Login} />;
  }
  return <>{children}</>;
};

export default ProtectedGuard;
