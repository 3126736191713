import { Dispatch, SetStateAction } from 'react';

import SearchBar from '../../../components/search-bar/SearchBar';

type PcfRequestsTableHeaderProps = {
  setSearch: Dispatch<SetStateAction<string>>;
};

const PcfRequestsTableHeader = ({ setSearch }: PcfRequestsTableHeaderProps) => {
  return (
    <div className='flex justify-content-end align-items-center flex-wrap'>
      <SearchBar onSearchChange={setSearch} />
    </div>
  );
};

export default PcfRequestsTableHeader;
