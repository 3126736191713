import { createContext } from 'react';

import { INotificationContext } from '../../shared/interfaces/INotificationContext';

const defaultState: INotificationContext = {
  notify: (): void => {
    throw new Error('notify() must be implemented');
  },
  isNewNotification: false,
  setIsNewNotification: (): void => {
    throw new Error('setIsNewNotification() must be implemented');
  },
};

const NotificationContext = createContext(defaultState);

export default NotificationContext;
