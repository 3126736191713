import { PrimeReactContext } from 'primereact/api';
import { classNames } from 'primereact/utils';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Logo, { LogoType } from '../../components/logo';
import styles from './AuthLayout.module.css';
import SectionInfo from './components/section-info';

type AuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout = ({ children }: AuthLayoutProps) => {
  const { setRipple } = useContext(PrimeReactContext);

  const { t } = useTranslation();

  useEffect(() => {
    setRipple(true);
  }, []);

  return (
    <div
      className={classNames(
        'flex flex-column h-auto md:h-screen justify-content-center align-items-center',
        styles['auth-bg'],
      )}
    >
      <div className={classNames('grid', styles['auth-container'])}>
        <div
          className={classNames(
            'col-12 md:col-6 flex flex-column justify-content-center border-bottom-1 md:border-bottom-none border-right-none  border-300 p-6 md:p-8',
            styles['info-block'],
          )}
        >
          <section className='mb-4'>
            <h2 className='header-text text-color'>{t('authPage.content.heading')}</h2>
            <p className='text-lg text-color'>{t('authPage.content.subheading')}</p>
          </section>

          <SectionInfo
            title={t('authPage.content.titleOne')}
            description={t('authPage.content.descriptionOne')}
            iconClassName='first'
          />

          <SectionInfo
            title={t('authPage.content.titleTwo')}
            description={t('authPage.content.descriptionTwo')}
            iconClassName='second'
          />

          <SectionInfo
            title={t('authPage.content.titleThree')}
            description={t('authPage.content.descriptionThree')}
            iconClassName='third'
          />
        </div>
        <div
          className={classNames(
            'col-12 md:col-6 p-6 md:p-8 bg-white flex align-items-center',
            styles['auth-block'],
          )}
        >
          {children}
        </div>
        <div className='col-12 flex gap-2 justify-content-center align-items-center my-2'>
          <span className='text-white'>{t('authPage.content.poweredBy')}</span>
          <Logo
            className={classNames('w-auto', styles['circulartree-logo'])}
            type={LogoType.CIRCULARTREE}
            white
            href='https://www.circulartree.com?utm_source=app&utm_medium=login+screen&utm_campaign=pacific+backlink'
          />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
