import { Tag } from 'primereact/tag';

type ProductSmallInfoProps = {
  name: string;
  productId?: string;
};

export default function ProductSmallInfo({ name, productId }: ProductSmallInfoProps) {
  return (
    <>
      <h4 className='my-2'>{name}</h4>
      {productId && (
        <Tag
          className='bg-gray-50 text-900 text-base font-light px-2 py-0'
          rounded
          value={productId}
        />
      )}
    </>
  );
}
