import { Dialog } from 'primereact/dialog';

import styles from './PCFInfoDialog.module.css';

// Following the same props settings as the PrimeReact's Dialog component
type PCFInfoDialogProps = {
  visible?: boolean;
  onHide: () => void;
};

export default function PCFInfoDialog({ visible, onHide }: PCFInfoDialogProps) {
  return (
    <Dialog
      className={styles['pcf-info-dialog']}
      closable
      blockScroll
      closeOnEscape
      dismissableMask
      visible={visible}
      onHide={onHide}
    >
      <h3>BASF follows the rules of Chemical Industry who agreed on using TFS</h3>
      <h4>TfS PCF Guideline</h4>
      <p>
        Calculating the carbon footprint of products provides the best product-level information for
        identification, tracking and reducing scope 3 GHG emissions in the industry.
      </p>
      <p>
        In 2022, TfS launched the Product Carbon Footprint (PCF) Guideline dedicated to calculating
        the carbon footprint of chemical materials. The Guideline is designed to cover Corporate
        Accounting and allows suppliers and companies to calculate PCFs and Corporate Scope 3
        Category 1 (Scope 3.1 - purchased goods and services) GHG emissions. The PCF Guideline is an
        open-source. By using it, suppliers and companies can work together to calculate their
        carbon footprints and reduce their GHG emissions. This will help decarbonise the entire
        industry together.
      </p>
      <h4>TfS White Paper: Improving and harmonising Scope 3 Reporting</h4>
      <p>
        The TfS White Paper explores the challenges and opportunities for harmonising carbon
        accounting methodologies in the chemical industry. It discusses the complexities involved
        and suggests strategies for a more sustainable future. The paper identifies three key
        modifications that are vital for the chemical industry to implement to improve carbon
        accounting: Biogenic carbon accounting, Mass Balance as a transition mechanism, and Recycled
        materials and content. The White Paper highlights the need for a reimagined corporate
        reporting methodology, which is crucial for the chemical industry&apos;s progress towards a
        circular economy. This will encourage resource efficiency and reduce GHG emissions.
      </p>
      <span>Source: </span>
      <a
        className='text-blue-600 underline'
        href='https://www.tfs-initiative.com/how-we-do-it/scope-3-ghg-emissions'
        target='_blank'
        rel='noreferrer'
      >
        Scope 3 GHG Emissions Programme - TFS Initiative
      </a>
    </Dialog>
  );
}
