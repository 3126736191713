import { IconOptions } from 'primereact/utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const InfoIconPrimary = (options: IconOptions) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.41076 4.07733C7.56705 3.92105 7.77901 3.83325 8.00002 3.83325C8.22103 3.83325 8.43299 3.92105 8.58928 4.07733C8.74556 4.23361 8.83335 4.44557 8.83335 4.66659C8.83335 4.8876 8.74556 5.09956 8.58928 5.25584C8.43299 5.41212 8.22103 5.49992 8.00002 5.49992C7.77901 5.49992 7.56705 5.41212 7.41076 5.25584C7.25448 5.09956 7.16669 4.8876 7.16669 4.66659C7.16669 4.44557 7.25448 4.23361 7.41076 4.07733ZM7.55146 6.93064C7.66682 6.81177 7.82464 6.74355 7.99025 6.74097C8.0739 6.73966 8.15697 6.75516 8.23452 6.78654C8.31207 6.81793 8.38253 6.86456 8.44172 6.92368C8.50092 6.9828 8.54764 7.0532 8.57913 7.13071C8.61061 7.20822 8.62622 7.29127 8.62502 7.37492V11.9583C8.62619 12.0411 8.61089 12.1233 8.58001 12.2002C8.54912 12.277 8.50327 12.347 8.44512 12.4059C8.38697 12.4649 8.31767 12.5118 8.24126 12.5437C8.16485 12.5757 8.08285 12.5922 8.00002 12.5922C7.91719 12.5922 7.83519 12.5757 7.75878 12.5437C7.68237 12.5118 7.61307 12.4649 7.55492 12.4059C7.49677 12.347 7.45092 12.277 7.42003 12.2002C7.38915 12.1233 7.37385 12.0411 7.37502 11.9583V7.37492C7.37264 7.2093 7.4361 7.04951 7.55146 6.93064Z'
        fill='#F39500'
        {...options.iconProps}
      />
    </svg>
  );
};

export default InfoIconPrimary;
