import Image from '../image';

type EmptyScreenProps = {
  imageSrc?: string;
  children: React.ReactNode;
};

export default function EmptyScreen({ imageSrc, children }: EmptyScreenProps) {
  return (
    <div className='flex flex-column flex-grow-1 pt-5 md:pt-8 align-items-center'>
      {imageSrc && <Image src={imageSrc} className='w-auto h-10rem md:h-23rem' />}
      {children}
    </div>
  );
}
