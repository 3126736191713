import { API } from 'aws-amplify';

import IAmplifyApiConfig from '../shared/interfaces/IAmplifyApiConfig';
import { IApiResponse } from '../shared/interfaces/IApiResponse';

type AmplifyApiGetProps = {
  config: IAmplifyApiConfig;
};

type AmplifyApiPostProps<T> = AmplifyApiGetProps & {
  body: T;
};

type RequestFunctionProps = IAmplifyApiConfig;

type RequestFunction<T> = (
  apiName: string,
  path: string,
  init: {
    [key: string]: unknown;
  },
) => Promise<IApiResponse<T>>;

type HandleRequestProps<T> = {
  requestFunction: RequestFunction<T>;
  requestFunctionProps: RequestFunctionProps;
};

class AmplifyApiService {
  private async handleRequest<T>({
    requestFunction,
    requestFunctionProps,
  }: HandleRequestProps<T>): Promise<T> {
    const { apiName, path, init } = requestFunctionProps;

    try {
      const response: IApiResponse<T> = await requestFunction(apiName, path, init);

      return response.body.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  async get<T>({ config }: AmplifyApiGetProps): Promise<T> {
    const { apiName, path, init } = config;

    return this.handleRequest<T>({
      requestFunction: API.get.bind(API),
      requestFunctionProps: { apiName, path, init },
    });
  }

  async post<T, R>({ config, body }: AmplifyApiPostProps<T>): Promise<R> {
    const { apiName, path, init } = config;

    if (body) {
      init.body = { ...body };
    }

    return this.handleRequest<R>({
      requestFunction: API.post.bind(API),
      requestFunctionProps: { apiName, path, init },
    });
  }

  async patch<T, R>({ config, body }: AmplifyApiPostProps<T>): Promise<R> {
    const { apiName, path, init } = config;

    if (body) {
      init.body = { ...body };
    }

    return this.handleRequest<R>({
      requestFunction: API.patch.bind(API),
      requestFunctionProps: { apiName, path, init },
    });
  }

  async put<T, R>({ config, body }: AmplifyApiPostProps<T>): Promise<R> {
    const { apiName, path, init } = config;

    if (body) {
      init.body = { ...body };
    }

    return this.handleRequest<R>({
      requestFunction: API.put.bind(API),
      requestFunctionProps: { apiName, path, init },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleError(error: any): never {
    if (error.response) {
      throw new Error('Amplify API Error: ' + error.response.data.message);
    }
    if (error instanceof Error) {
      throw new Error('Amplify API Error: ' + error.message);
    }
    throw new Error('Amplify API Error: ' + error);
  }
}

export default AmplifyApiService;
