import { Checkbox } from 'primereact/checkbox';
import { ComponentProps, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Locale } from '../../../../../contexts/language/LanguageContext';
import { parseDateWithoutTime } from '../../../../../shared/helpers/parse-date';
import { IProductDetails } from '../../../interfaces/ProductDetails.interface';
import InfoCard from '../../info-card/InfoCard';
import InfoCardRow from '../../info-card-row/InfoCardRow';

interface CarbonFootprintDetailsSectionProps extends ComponentProps<'div'> {
  productDetails?: IProductDetails;
  isLoading?: boolean;
}

const CarbonFootprintDetailsSection = (props: CarbonFootprintDetailsSectionProps): JSX.Element => {
  const { productDetails, isLoading, ...rest } = props;
  const {
    i18n: { language },
    t,
  } = useTranslation();

  return (
    <InfoCard isLoading={isLoading} {...rest}>
      <h5>
        {t('productDetailsPage.sections.carbonFootprintDetailsSection.carbonFootprintDetails')}
      </h5>

      <InfoCardRow
        label={t('productDetailsPage.sections.carbonFootprintDetailsSection.crossSectoralStandard')}
      >
        {productDetails?.pcf.crossSectoralStandardsUsed[0].crossSectoralStandard}
      </InfoCardRow>

      <InfoCardRow
        label={t(
          'productDetailsPage.sections.carbonFootprintDetailsSection.productOrSectorSpecificRules',
        )}
      >
        {productDetails?.pcf.productOrSectorSpecificRules[0].productOrSectorSpecificRules
          .map((rule) => {
            return rule.ruleName;
          })
          .join(' | ')}
      </InfoCardRow>

      <InfoCardRow
        label={t('productDetailsPage.sections.carbonFootprintDetailsSection.secondaryDataSources')}
      >
        {productDetails?.pcf.secondaryEmissionFactorSources
          .map((item) => {
            return item.secondaryEmissionFactorSource;
          })
          .join(' | ')}
      </InfoCardRow>

      <InfoCardRow
        label={t(
          'productDetailsPage.sections.carbonFootprintDetailsSection.characterizationFactors',
        )}
      >
        {productDetails?.pcf.extWBCSD_characterizationFactors}
      </InfoCardRow>

      <InfoCardRow
        label={t('productDetailsPage.sections.carbonFootprintDetailsSection.packagingInclusion')}
      >
        <Checkbox
          checked={productDetails?.pcf.extWBCSD_packagingEmissionsIncluded || false}
          disabled
        />
      </InfoCardRow>

      <InfoCardRow label={t('productDetailsPage.sections.carbonFootprintDetailsSection.boundary')}>
        {productDetails?.partialFullPcf}
      </InfoCardRow>

      <InfoCardRow
        label={t('productDetailsPage.sections.carbonFootprintDetailsSection.reportingPeriod')}
      >
        {parseDateWithoutTime(
          productDetails?.pcf.referencePeriodStart,
          language as Locale,
        ).toLocaleString()}{' '}
        -{' '}
        {parseDateWithoutTime(
          productDetails?.pcf.referencePeriodEnd,
          language as Locale,
        ).toLocaleString()}
      </InfoCardRow>

      <InfoCardRow
        label={t('productDetailsPage.sections.carbonFootprintDetailsSection.dateCreated')}
      >
        {parseDateWithoutTime(productDetails?.created, language as Locale).toLocaleString()}
      </InfoCardRow>
    </InfoCard>
  );
};

export default memo(CarbonFootprintDetailsSection);
