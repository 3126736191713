import { User } from '../../../../../shared/types/User';

export default function (user: User | null, language: 'en' | 'de' = 'en'): string {
  return language === 'en'
    ? `Dear PACIFIC Team,

    I am reaching out for assistance regarding PACIFIC Web Application. Here are the details of my report:

    - Full Name: ${user?.givenName} ${user?.familyName}
    - Account Email: ${user?.email}
    - Issue:

    - Find attached screenshots that might help in understanding the issue better:

    Thank you for your attention and feedback to this matter.

    Best regards,

    ${user?.givenName ?? ''} ${user?.familyName ?? ''}`
    : `Sehr geehrtes PACIFIC-Team,

  ich wende mich an Sie, um Unterstützung bezüglich der PACIFIC-Webanwendung zu erhalten. Hier sind die Details meiner Anfrage:

  - Vollständiger Name: ${user?.givenName ?? ''} ${user?.familyName ?? ''}
  - Kontoe-Mail: ${user?.email}
  - Meine Frage:

  Vielen Dank für Ihre Aufmerksamkeit und Rückmeldung zu diesem Anliegen.

  Mit freundlichen Grüßen,

  ${user?.givenName ?? ''} ${user?.familyName ?? ''}`;
}
