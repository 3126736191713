import CustomSpinner from '../custom-spinner/CustomSpinner';

const FullScreenLoader = () => {
  return (
    <div className='flex justify-content-center items-center h-screen w-screen'>
      <CustomSpinner className='w-12rem' />
    </div>
  );
};

export default FullScreenLoader;
