import { classNames } from 'primereact/utils';

import styles from './index.module.css';

export type SectionInfoProps = {
  title: string;
  description: string;
  iconClassName?: 'first' | 'second' | 'third';
};

export default function SectionInfo({ title, description, iconClassName }: SectionInfoProps) {
  return (
    <section className={classNames('flex align-items-start gap-4', styles['section-info'])}>
      <div className={styles['section-icon']}>
        <i
          className={classNames(styles['info-icon'], {
            [styles[iconClassName!] as string]: !!iconClassName, // eslint-disable-line @typescript-eslint/no-non-null-assertion
          })}
        />
      </div>
      <div>
        <p className={styles['info-header-text']}>{title}</p>
        <p className='text-black-alpha-90'>{description}</p>
      </div>
    </section>
  );
}
