import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';

import useKampylSDKUpdatePageView from '../../../../../../../hooks/useKampylSDKUpdatePageView';
import styles from './CancelButton.module.css';

type CancelButtonProps = {
  onCancel: () => void;
};

const CancelButton = ({ onCancel }: CancelButtonProps): JSX.Element => {
  const { t } = useTranslation();

  // This is a script to be able to show the NPS Dialog
  useKampylSDKUpdatePageView();

  return (
    <div className={styles['cancel-button']}>
      <Button
        label={t('cancelButtonText')}
        onClick={onCancel}
        className={classNames('p-button-text', 'trigger-nps-dialog')}
        id='pcf-request-cancel-btn'
      />
    </div>
  );
};

export default CancelButton;
