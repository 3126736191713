import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';

import FilePDFIcon from '../../assets/icons/FilePDF';
import { useGetRequestPdfDownloadUrl } from '../../hooks/api/request-pcf';

export type DownloadPdfFileButtonProps = {
  requestId?: number;
  className?: string;
  showDownloadText?: boolean;
};

const DownloadPdfFileButton = ({
  requestId,
  className,
  showDownloadText = false,
}: DownloadPdfFileButtonProps) => {
  const { refetch: fetchPDFDownloadUrl } = useGetRequestPdfDownloadUrl(requestId);

  const handleClick = async () => {
    const { data, isSuccess } = await fetchPDFDownloadUrl();

    if (isSuccess && data.downloadUrl) {
      window.open(data.downloadUrl, '_blank');
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <Button
        className={classNames('p-0', className)}
        icon={FilePDFIcon}
        link
        onClick={handleClick}
      />
      {showDownloadText ? (
        <Button className='text-primary underline' link onClick={handleClick}>
          {t('downloadPcfDialog.downloadPdfDocument')}
        </Button>
      ) : null}
    </>
  );
};

export default DownloadPdfFileButton;
