import { useState } from 'react';

import { IProduct } from '../../../shared/interfaces/IProduct';
import RequestPcfContent from './components/request-pcf-content/RequestPcfContent';
import RequestSentContent from './components/request-sent-content/RequestSentContent';

type RequestPcfModalProps = {
  product: IProduct;
  onClose: () => void;
};

const RequestPcfModal = ({ onClose, product }: RequestPcfModalProps) => {
  const [isRequestPcfVisible, setIsRequestPcfVisible] = useState(true);
  const [isRequestSentVisible, setIsRequestSentVisible] = useState(false);

  const handlePcfRequest = () => {
    setIsRequestPcfVisible(false);
    setIsRequestSentVisible(true);
  };

  return (
    <>
      {isRequestPcfVisible && (
        <RequestPcfContent product={product} onCancel={onClose} onRequestPcf={handlePcfRequest} />
      )}
      {isRequestSentVisible && <RequestSentContent product={product} onDone={onClose} />}
    </>
  );
};

export default RequestPcfModal;
