import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import styles from './SearchBar.module.css';

type SearchBarProps = {
  onSearchChange: (searchStr: string) => void;
};

const SearchBar = ({ onSearchChange }: SearchBarProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebouncedCallback(onSearchChange, 500);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debouncedSearch(event.target.value);
  };

  return (
    <span className={classNames('p-input-icon-left', styles['search-bar'])}>
      <i className='pi pi-search' />
      <InputText
        className='w-full h-full'
        value={search}
        onChange={onChange}
        placeholder={t('global.typeToSearch')}
      />
    </span>
  );
};

export default SearchBar;
