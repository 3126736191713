import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppRoutes } from '../../../../../shared/enums/app-routes';
import { License } from '../../../../../shared/interfaces/ICompany';
import styles from './AboutModal.module.css';

type AboutModalProps = {
  visible: boolean;
  onHide: () => void;
  showCatenaXConfigration?: boolean;
  license?: License;
};

export default function AboutModal(props: AboutModalProps) {
  const { t } = useTranslation();

  const renderDialogHeader = () => {
    return <h3 className='text-center m-0'>{t('userButton.pacific')}</h3>;
  };

  return (
    <Dialog
      header={renderDialogHeader}
      visible={props.visible}
      onHide={props.onHide}
      pt={{ content: { className: 'pb-2' } }}
    >
      <div className='flex flex-column justify-content-center align-items-center pt-2 mb-4 px-8'>
        <div className='mb-4 text-600'> {t('about.copyrightText')}</div>

        <Link className='text-blue-500 text-sm mt-2' to={AppRoutes.TermsOfUse} target='_blank'>
          {t('about.termsOfUse')}
        </Link>
        <Link className='text-blue-500 text-sm mt-2' to={AppRoutes.PrivacyPolicy} target='_blank'>
          {t('about.privacyPolicy')}
        </Link>
      </div>
      {props.license && (
        <div
          className={classNames('text-sm text-center', {
            [styles['text-green']]: props.license === 'pro',
            'text-primary': props.license === 'automotive',
          })}
        >
          {t(`about.license.${props.license}`)}
        </div>
      )}
    </Dialog>
  );
}
