// created enums to manage all routes through single file
export enum AppRoutes {
  Login = '/login',
  ActivateAccount = '/activate-account',
  Products = '/products',
  ProductDetails = '/products/:productId',
  Requests = '/requests',
  NotFound = '/404',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  PrivacyPolicy = '/privacy-policy',
  TermsOfUse = '/terms-of-use',
}
