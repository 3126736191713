import { InputTextarea, InputTextareaProps } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { FieldValues, useController, UseControllerProps } from 'react-hook-form';

import ErrorMessage from '../../error-message';
import styles from './TextAreaInput.module.css';

export type TextAreaInputProps<T extends FieldValues> = Omit<InputTextareaProps, 'name'> & {
  label?: string;
} & UseControllerProps<T>;

const TextAreaInput = <T extends FieldValues>({
  name,
  control,
  rules,
  defaultValue,
  label,
  rows,
  cols,
  ...props
}: TextAreaInputProps<T>) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <div className={classNames('field p-fluid', styles['text-area-input'])}>
      <span className='p-float-label'>
        <InputTextarea id={name} ref={ref} rows={rows} cols={cols} {...props} {...inputProps} />
        {label && <label htmlFor={name}>{label}</label>}
      </span>
      {invalid && <ErrorMessage message={error?.message || ''} />}
    </div>
  );
};

export default TextAreaInput;
