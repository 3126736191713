import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';

import FileJSONIcon from '../../assets/icons/FileJSON';
import { useGetRequestJsonDownloadUrl } from '../../hooks/api/request-pcf';

export type DownloadJsonFileButtonProps = {
  className?: string;
  productName: string;
  requestId: number;
  showDownloadText?: boolean;
};

const DownloadJsonFileButton = ({
  className,
  productName,
  requestId,
  showDownloadText = false,
}: DownloadJsonFileButtonProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { refetch: fetchJsonDownload } = useGetRequestJsonDownloadUrl(requestId);

  const handleClick = async () => {
    const { data, isSuccess } = await fetchJsonDownload();

    if (isSuccess && data) {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
      const link = document.createElement('a');
      link.href = jsonString;
      link.download = `${productName.toLowerCase().replace(' ', '_')}_pcf.json`;

      link.click();

      queryClient.invalidateQueries({ queryKey: ['listProducts'] });
    }
  };

  return (
    <>
      <Button
        className={classNames('p-0', className)}
        icon={FileJSONIcon}
        link
        onClick={handleClick}
      />
      {showDownloadText ? (
        <Button className='text-primary underline' link onClick={handleClick}>
          {t('downloadPcfDialog.downloadJSONFile')}
        </Button>
      ) : null}
    </>
  );
};

export default DownloadJsonFileButton;
