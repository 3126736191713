import { SUFFIX_EMISSION } from '../../shared/constants/products.constant';

type EmissionUnitProps = {
  value: string | number;
};

export default function EmissionUnit({ value }: EmissionUnitProps) {
  return (
    <>
      <span className='mr-1 text-lg font-semibold'>{value}</span>
      {SUFFIX_EMISSION}
    </>
  );
}
