export enum ProductRegion {
  AsiaPacific = 'Asia Pacific',
  SouthAmerica = 'South America',
  NorthAmerica = 'North America',
  Europe = 'Europe',
  Global = 'Global',
}

export const mapProductRegionToKey = (productRegion: ProductRegion): string => {
  switch (productRegion) {
    case ProductRegion.AsiaPacific:
      return 'asiaPacific';
    case ProductRegion.SouthAmerica:
      return 'southAmerica';
    case ProductRegion.NorthAmerica:
      return 'northAmerica';
    case ProductRegion.Europe:
      return 'europe';
    case ProductRegion.Global:
      return 'global';
    default:
      return '';
  }
};
