import { Tag } from 'primereact/tag';

import styles from './ProductId.module.css';

type ProductIdProps = {
  productId?: string;
  requestedProductId?: string;
};

export default function ProductId({
  productId,
  requestedProductId,
}: ProductIdProps): JSX.Element | null {
  if (!productId) {
    return null;
  }

  const showRequestedProductId = requestedProductId && requestedProductId !== productId;

  return (
    <div className='flex flex-column relative'>
      <div>
        <Tag
          className='text-sm font-light '
          value={productId}
          pt={{
            root: {
              className: styles['product-id-tag'],
            },
            value: {
              className: styles['product-id-tag-value'],
            },
          }}
        />
      </div>
      {showRequestedProductId && (
        <div className='absolute top-100'>
          <Tag
            className='text-sm font-light'
            value={requestedProductId}
            pt={{
              root: {
                className: styles['requested-product-id-tag'],
              },
              value: {
                className: styles['requested-product-id-tag-value'],
              },
            }}
          />
        </div>
      )}
    </div>
  );
}
