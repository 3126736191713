/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import TextAreaInput from '../../../../../components/forms/text-area-input/TextAreaInput';
import TextInput from '../../../../../components/forms/text-input/TextInput';
import { useCreatePcfRequest } from '../../../../../hooks/api/request-pcf';
import { IProduct } from '../../../../../shared/interfaces/IProduct';
import CancelButton from './components/cancel-button/CancelButton';

type RequestPcfContentProps = {
  product: IProduct;
  onCancel: () => void;
  onRequestPcf: () => void;
};

type FormInput = {
  productName: string;
  productId: string;
  comment?: string;
};

export default function RequestPcfContent({
  product,
  onCancel,
  onRequestPcf,
}: RequestPcfContentProps) {
  const queryClient = useQueryClient();

  const mySchema = yup.object().shape({
    productName: yup.string().required(),
    productId: yup.string().required(),
    comment: yup.string().max(1000, 'Comment should be maximum 1000 characters').optional(),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      productName: product.product_name,
      productId: product.product_cid,
      comment: '',
    },
    resolver: yupResolver(mySchema),
  });

  const onSuccess = (): void => {
    onRequestPcf();

    // Invalidate products and requests endpoints
    queryClient.invalidateQueries({ queryKey: ['listProducts'] });
    queryClient.invalidateQueries({ queryKey: ['requests'] });
  };

  const { isLoading: isCreatingPcfRequest, mutate: requestPcf } = useCreatePcfRequest({
    onSuccess,
  });

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const comment = data.comment;

    /* eslint-disable camelcase */
    requestPcf({
      product_id: +product.product_id,
      file_type: ['pdf', 'json'],
      request_type: 'pcf',
      request_description: comment,
    });
    /* eslint-enable camelcase */
  };

  const { t } = useTranslation();

  const footerContent = (
    <div className='flex flex-row w-full justify-content-end '>
      <CancelButton onCancel={onCancel} />

      <Button
        label={
          !isCreatingPcfRequest
            ? t('requestPcfDialog.requestPCF')
            : t('requestPcfDialog.requesting')
        }
        onClick={handleSubmit(onSubmit)}
        loading={isCreatingPcfRequest}
        disabled={isCreatingPcfRequest}
      />
    </div>
  );

  return (
    <Dialog
      header={t('requestPcfDialog.dialogHeader')}
      visible
      className='bg-white'
      onHide={onCancel}
      footer={footerContent}
    >
      <div className='my-4'>
        <TextInput
          name='productName'
          type='text'
          label={t('additionalProductRequest.productName')}
          control={control}
          disabled
        />
      </div>
      <div className='my-4'>
        <TextInput
          name='productId'
          type='text'
          label={t('additionalProductRequest.productId')}
          control={control}
          disabled
        />
      </div>
      <div className='my-4'>
        <TextAreaInput
          name='comment'
          label={t('additionalProductRequest.comment')}
          control={control}
          rows={6}
          className='w-full h-full'
        />
      </div>
    </Dialog>
  );
}
