import { ProgressSpinner, ProgressSpinnerPassThroughType } from 'primereact/progressspinner';
import { classNames } from 'primereact/utils';
import { HTMLAttributes } from 'react';

import styles from './CustomSpinner.module.css';

type CustomSpinnerProps = {
  color?: string;
  strokeWidth?: number;
  className?: string;
};

const CustomSpinner = ({ color, strokeWidth, className }: CustomSpinnerProps): JSX.Element => {
  return (
    <ProgressSpinner
      className={classNames(styles['spinner'], className)}
      pt={{
        circle: {
          style: {
            strokeWidth: strokeWidth,
            stroke: color,
          },
        } as ProgressSpinnerPassThroughType<HTMLAttributes<SVGCircleElement>>,
      }}
    />
  );
};

export default CustomSpinner;
