import { BreadCrumb } from 'primereact/breadcrumb';
import { Skeleton } from 'primereact/skeleton';
import { useTranslation } from 'react-i18next';

import styles from './ProductBreadcrumb.module.css';

type ProductBreadCrumbProps = {
  isLoading?: boolean;
  productName?: string;
};

const ProductBreadCrumb = ({ isLoading, productName }: ProductBreadCrumbProps): JSX.Element => {
  const { t } = useTranslation();

  const home = {
    label: t('productDetailsPage.breadcrumb.products'),
    url: '/products',
    className: styles['home-item'],
  };
  const items = [{ label: productName, disabled: true }];

  if (isLoading) {
    return (
      <div className='px-3 py-2'>
        <Skeleton height='1.5rem' width='20%' />
      </div>
    );
  }

  return <BreadCrumb model={items} home={home} className='bg-transparent border-none px-3 py-2' />;
};

export default ProductBreadCrumb;
