import i18next from 'i18next';
import languageDectactor from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import GERMAN_TRANSLATION from './assets/translations/de.json';
import ENGLISH_TRANSLATION from './assets/translations/en.json';

i18next
  .use(initReactI18next)
  .use(languageDectactor)
  .init({
    debug: true,
    lng: navigator.language === 'de' ? 'de' : 'en',
    fallbackLng: 'en',
    supportedLngs: ['en', 'de'],
    resources: {
      en: ENGLISH_TRANSLATION,
      de: GERMAN_TRANSLATION, // Use English translations until we have German translations
    },
    detection: {
      order: ['path', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'],
    },
  });

export default i18next;
