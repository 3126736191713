import { Tag } from 'primereact/tag';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router-dom';

import styles from './ProductName.module.css';

type ProductNameProps = {
  productName?: string;
  requestedProductName?: string;
  href?: string;
};

const ProductName = ({
  productName,
  requestedProductName,
  href,
}: ProductNameProps): JSX.Element | null => {
  if (!productName) {
    return null;
  }

  const showRequestedProductName = requestedProductName && requestedProductName !== productName;
  const navigate = useNavigate();

  const onProductNameClick = () => {
    if (href) {
      navigate(href);
    }
  };

  return (
    <div className='flex flex-column relative'>
      <div>
        <Tag
          className={classNames('text-base font-normal', {
            'cursor-pointer': !!href,
            'hover:underline': !!href,
          })}
          value={productName}
          pt={{
            root: {
              className: styles['product-name-tag'],
            },
            value: {
              className: styles['product-name-tag-value'],
            },
          }}
          onClick={onProductNameClick}
        />
      </div>
      {showRequestedProductName && (
        <div className='absolute top-100'>
          <Tag
            className='text-sm font-light'
            value={requestedProductName}
            pt={{
              root: {
                className: styles['requested-product-name-tag'],
              },
              value: {
                className: styles['requested-product-name-tag-value'],
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProductName;
