import { Skeleton } from 'primereact/skeleton';
import { classNames } from 'primereact/utils';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetProductDetails } from '../../hooks/api/products';
import ProductBreadCrumb from './components/product-breadcrumb/ProductBreadcrumb';
import CarbonFootprintDetailsSection from './components/sections/carbon-footprint-details-section/CarbonFootprintDetails';
import GeneralDetailsSection from './components/sections/general-details-section/GeneralDetailsSection';
import ProductCarbonFootprintSection from './components/sections/product-carbon-footprint-section/ProductCarbonFootprintSection';
import styles from './ProductDetailsPage.module.css';

const ProductDetailsPage = (): JSX.Element => {
  const { productId } = useParams() as { productId: string };

  const navigate = useNavigate();

  // Fetch JSON data from Pacific API
  const {
    isLoading,
    isError,
    data: productDetails,
  } = useGetProductDetails({ productId, options: { retry: 1 } });

  if (isError) {
    navigate('/404');
  }

  return (
    <div className={classNames('p-5 flex flex-column gap-3', styles['product-details-page'])}>
      <ProductBreadCrumb isLoading={isLoading} productName={productDetails?.productName} />

      <h1 className='text-3xl px-3'>
        {isLoading ? <Skeleton height='29.39px' width='25%' /> : productDetails?.productName}
      </h1>

      <div className='grid m-0 gap-3'>
        <GeneralDetailsSection
          productDetails={productDetails}
          isLoading={isLoading}
          className='col-12 lg:col'
        />
        <ProductCarbonFootprintSection
          productDetails={productDetails}
          isLoading={isLoading}
          className='col-12 lg:col'
        />
      </div>

      <CarbonFootprintDetailsSection productDetails={productDetails} isLoading={isLoading} />
    </div>
  );
};

export default ProductDetailsPage;
