import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';

import PcfRequestsTable from './components/PcfRequestsTable';
import styles from './RequestsPage.module.css';

const RequestsPage = () => {
  const { t } = useTranslation();

  return (
    <div className={classNames('p-5 flex flex-column', styles['requests-page'])}>
      <h1 className='text-2xl md:text-4xl'>{t('pcfRequest.title')}</h1>
      <PcfRequestsTable />
    </div>
  );
};

export default RequestsPage;
