import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';

import { SUPPORT_EMAIL } from '../../../../../../shared/constants/emails.constant';
import { User } from '../../../../../../shared/types/User';
import generateEmailBody from './generateEmailBody';

interface ReportBugProps {
  className?: string;
  user: User | null;
}

const ReportBug = ({ className, user }: ReportBugProps) => {
  const { t, i18n } = useTranslation();

  const onReportBugClick = () => {
    const emailSubject = t('emails.reportBug.subject');
    let emailBody = generateEmailBody(user, i18n.language as 'en' | 'de');

    // Encode the email body for the mailto link
    emailBody = encodeURIComponent(emailBody);

    // Create the mailto link with the recipient, subject, and body
    const mailtoLink = `mailto:${SUPPORT_EMAIL}?subject=${emailSubject}&body=${emailBody}`;

    // Open the user's default email client with the mailto link
    window.location.href = mailtoLink;
  };

  return (
    <Button
      text
      className={classNames('pl-4 border-noround', className)}
      onClick={onReportBugClick}
    >
      <i className='cb cb-icon-bug user-icon' />
      <span className='pl-3'> {t('userButton.reportBug')}</span>
    </Button>
  );
};

export default ReportBug;
