import { IconOptions } from 'primereact/utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const FilePDFIcon = (options: IconOptions) => {
  return (
    <svg
      width='36'
      height='40'
      viewBox='0 0 36 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...options.iconProps}
    >
      <path
        d='M4.66675 36.6666V1.66659L6.66675 0.333252L27.3334 0.666585V6.33325L28.3334 7.33325L34.0001 7.66659L34.3334 7.99992V35.6666L33.3334 37.3333L4.66675 36.6666Z'
        fill='white'
      />
      <path
        d='M27.3335 6.33333V1.33333L27.6668 1L34.0002 7V7.66667H30.6668L28.3335 7.33333L27.3335 6.33333Z'
        fill='#FAFBFC'
      />
      <path
        d='M34.3335 7.5V34.5C34.3335 36.1569 32.9904 37.5 31.3335 37.5H7.3335C5.67664 37.5 4.3335 36.1569 4.3335 34.5V3.5C4.3335 1.84315 5.67664 0.5 7.3335 0.5H27.3335M34.3335 7.5L27.3335 0.5M34.3335 7.5H30.3335C28.6766 7.5 27.3335 6.15685 27.3335 4.5V0.5'
        stroke='#DFE0EB'
        strokeWidth='1.5'
      />
      <path
        d='M14.8335 20.4998H20.1668'
        stroke='#DFE0EB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.8335 24.2341H21.8335'
        stroke='#DFE0EB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.8335 22.3669H23.5002'
        stroke='#DFE0EB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <mask id='path-7-inside-1_6685_7489' fill='white'>
        <rect x='14.0002' y='13.1665' width='10.6667' height='5.33333' rx='1' />
      </mask>
      <rect
        x='14.0002'
        y='13.1665'
        width='10.6667'
        height='5.33333'
        rx='1'
        stroke='#DFE0EB'
        strokeWidth='3'
        mask='url(#path-7-inside-1_6685_7489)'
      />
      <rect x='0.000244141' y='27.9998' width='24' height='12' rx='2' fill='#F39500' />
      <path
        d='M3.85238 36.9998V30.4543H6.30693C6.80977 30.4543 7.23165 30.5481 7.57255 30.7356C7.91559 30.9231 8.17447 31.1809 8.34919 31.509C8.52603 31.835 8.61446 32.2057 8.61446 32.6212C8.61446 33.0409 8.52603 33.4138 8.34919 33.7398C8.17234 34.0658 7.91133 34.3226 7.56616 34.5101C7.22099 34.6954 6.79592 34.7881 6.29095 34.7881H4.66417V33.8133H6.13115C6.42518 33.8133 6.66595 33.7622 6.85345 33.6599C7.04095 33.5576 7.17944 33.417 7.26893 33.238C7.36055 33.0591 7.40636 32.8534 7.40636 32.6212C7.40636 32.389 7.36055 32.1844 7.26893 32.0076C7.17944 31.8307 7.03988 31.6933 6.85025 31.5953C6.66275 31.4951 6.42092 31.4451 6.12476 31.4451H5.03811V36.9998H3.85238ZM11.8624 36.9998H9.64438V30.4543H11.9072C12.557 30.4543 13.1153 30.5853 13.5819 30.8474C14.0506 31.1074 14.4107 31.4813 14.6621 31.9692C14.9136 32.4571 15.0393 33.0409 15.0393 33.7206C15.0393 34.4025 14.9125 34.9884 14.6589 35.4784C14.4075 35.9685 14.0442 36.3446 13.5691 36.6066C13.0961 36.8687 12.5272 36.9998 11.8624 36.9998ZM10.8301 35.9738H11.8049C12.2609 35.9738 12.6412 35.8907 12.9459 35.7245C13.2506 35.5562 13.4796 35.3059 13.633 34.9735C13.7864 34.639 13.8631 34.2213 13.8631 33.7206C13.8631 33.2199 13.7864 32.8044 13.633 32.4742C13.4796 32.1418 13.2527 31.8936 12.9523 31.7295C12.654 31.5633 12.2832 31.4802 11.84 31.4802H10.8301V35.9738ZM16.1659 36.9998V30.4543H20.359V31.4483H17.3516V33.2253H20.0714V34.2192H17.3516V36.9998H16.1659Z'
        fill='white'
      />
    </svg>
  );
};

export default FilePDFIcon;
