import { classNames } from 'primereact/utils';
import { ImgHTMLAttributes, useState } from 'react';

import NoImage from '../../assets/images/no-image.png';
import CustomSpinner from '../custom-spinner/CustomSpinner';

type ImageProps = ImgHTMLAttributes<HTMLImageElement>;

export default function Image({ className, src, ...rest }: ImageProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [isImageError, setIsImageError] = useState(false);

  if (isImageError) {
    return <img {...rest} src={NoImage} className={className} />;
  }

  return (
    <>
      {isLoading && <CustomSpinner className='w-5rem' />}
      <img
        {...rest}
        src={src}
        className={classNames(className, { hidden: isLoading, 'inline-block': !isLoading })}
        onLoad={() => {
          return setIsLoading(false);
        }}
        onError={() => {
          return setIsImageError(true);
        }}
      />
    </>
  );
}
