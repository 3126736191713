import { useLayoutEffect, useRef, useState } from 'react';

// Hook to get the width of an element
const useWidth = (): [React.RefObject<HTMLDivElement>, number] => {
  const [width, setWidth] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };

    updateWidth();

    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return [ref, width];
};

export default useWidth;
