import { ComponentProps, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { parseProductIdFromPcfJson } from '../../../../../shared/helpers/parse-product-id-from-pcf-json';
import { IProductDetails } from '../../../interfaces/ProductDetails.interface';
import InfoCard from '../../info-card/InfoCard';
import InfoCardRow from '../../info-card-row/InfoCardRow';
import ProductIdTag from './components/product-id-tag/ProductIdTag';

interface GeneralDetailsSectionProps extends ComponentProps<'div'> {
  productDetails?: IProductDetails;
  isLoading?: boolean;
}

const GeneralDetailsSection = (props: GeneralDetailsSectionProps): JSX.Element => {
  const { productDetails, isLoading, ...rest } = props;

  const { t } = useTranslation();

  return (
    <InfoCard isLoading={isLoading} {...rest}>
      <h5>{t('productDetailsPage.sections.generalDetailsSection.generalDetails')}</h5>

      <InfoCardRow label={t('productDetailsPage.sections.generalDetailsSection.id')}>
        <ProductIdTag
          productId={parseProductIdFromPcfJson(productDetails?.productIds) || 'N/A'}
          rounded
          className='px-3 font-normal'
        />
      </InfoCardRow>

      <InfoCardRow label={t('productDetailsPage.sections.generalDetailsSection.supplier')}>
        <span>{productDetails?.companyName}</span>
      </InfoCardRow>

      <InfoCardRow label={t('productDetailsPage.sections.generalDetailsSection.geography')}>
        <span>{productDetails?.pcf.geographyRegionOrSubregion}</span>
      </InfoCardRow>
    </InfoCard>
  );
};

export default memo(GeneralDetailsSection);
