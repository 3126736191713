import { useQuery, UseQueryResult } from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import { ICompany } from '../../shared/interfaces/ICompany';
import { IUser } from '../../shared/interfaces/IUser';

type IUserDetails = Pick<IUser, 'first_name' | 'last_name' | 'email'> & {
  company: Pick<ICompany, 'company_id' | 'company_name' | 'edc_configuration_status' | 'license'>;
  language: string;
};

const amplifyApiService = new AmplifyApiService();

export const useGetUser = (): UseQueryResult<IUserDetails> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'user/me',
    init: {},
  };

  const query = useQuery<IUserDetails, Error>({
    queryKey: ['user', 'me'],
    queryFn: async (): Promise<IUserDetails> => {
      const response: IUserDetails = await amplifyApiService.get<IUserDetails>({
        config: amplifyApiConfig,
      });

      return response;
    },
    enabled: false,
  });

  return query;
};
