import React, { FC, useMemo } from 'react';

import { useAuth } from '../../hooks/auth/useAuth';
import LanguageContext, { Locale } from './LanguageContext';

type Props = {
  children: React.ReactNode;
};

export const LanguageProvider: FC<Props> = ({ children }) => {
  const { user } = useAuth();

  const languageValue = useMemo(() => {
    return { language: user?.language ?? Locale.en };
  }, [user?.language]);

  return <LanguageContext.Provider value={languageValue}>{children}</LanguageContext.Provider>;
};
