import { Toast } from 'primereact/toast';
import { useMemo, useRef, useState } from 'react';

import { NOTIFICATION_ICONS } from '../../shared/constants/notification-icons.constant';
import { INotificationContext } from '../../shared/interfaces/INotificationContext';
import { IToast } from '../../shared/interfaces/IToast';
import NotificationContext from './NotificationContext';

type NotificationProviderProps = {
  children: React.ReactNode;
};

const NotificationProvider = ({ children }: NotificationProviderProps): JSX.Element => {
  const toastRef = useRef<Toast>(null);

  const [isNewNotification, setIsNewNotification] = useState<boolean>(false);

  const notify = ({ severity, summary, detail, content, life }: IToast): void => {
    if (toastRef.current) {
      toastRef.current.show({
        severity,
        summary,
        detail,
        content,
        life,
        icon: (
          <i
            className={`pi pi-${NOTIFICATION_ICONS[severity]} p-notification-custom-icon ${severity}-background text-white	`}
          />
        ),
      });
    }
  };

  const state = useMemo<INotificationContext>(() => {
    return { notify, isNewNotification, setIsNewNotification };
  }, [notify]);

  return (
    <NotificationContext.Provider value={state}>
      <Toast ref={toastRef} />
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
